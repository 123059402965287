import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { styles } from "../globals"
import { StaticQuery, graphql } from "gatsby"

const GET_MAP = graphql`
  {
    getMap: file(relativePath: { eq: "google-maps.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Map() {
  return (
    <MapWrapper>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.0003685600427!2d15.625952214027897!3d43.79360539085952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x78f2bfe26aa10e54!2sJezera+Village+Holiday+Resort+-+%C5%A0kolji%C4%87+d.o.o.!5e0!3m2!1sen!2snl!4v1559916430087!5m2!1sen!2snl"
        width="100%"
        height="450"
        frameborder="0"
        style={{}}
        allowfullscreen
      />
      {/*     this code is from hostel Ljubas!  <a
        href="https://goo.gl/maps/KoGniiTnBRU9ETRc7"
        rel="noopener noreferrer"
        target="_blank"
      >
        <StaticQuery
          query={GET_MAP}
          render={data => {
            return (
              <Img fluid={data.getMap.childImageSharp.fluid} className="map" />
            )
          }}
        />
      </a> */}
    </MapWrapper>
  )
}

const MapWrapper = styled.div`
  overflow: hidden;
  .map {
    transform: scale(1.5);
  }

  @media (min-width: ${styles.smallScreenBreakpoint}) {
    width: 70vw;
    margin: 0 auto;
    .map {
      transform: scale(1);
    }
  }
`
