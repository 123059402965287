import React from "react"
import title from "../../gatsby-config"
import styled from "styled-components"
import { styles } from "../globals"

export default function Banner({ title, subtitle, children }) {
  return (
    <BannerWrapper>
      <h2 className="title">{title}</h2>
      <h3 className="subtitle">{subtitle}</h3>
      {children}
    </BannerWrapper>
  )
}

Banner.defaultProps = {
  title: title.siteMetadata.title,
}

const BannerWrapper = styled.div`
  text-align: center;
  .title,
  .subtitle {
    color: white;
    text-transform: capitalize;
  }
  .title {
    ${styles.letterSpacing({ spacing: "0.3rem" })};
    font-weight: 900;
    background: ${styles.mainPink};
    padding: 1rem 1.55rem;
  }
  .subtitle {
    ${styles.letterSpacing({})};
    font-weight: normal;
  }
`
