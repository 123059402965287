import React from "react"
import Article from "../components/article/Article"
import Banner from "../components/Banner"
import { DefaultHeader } from "../components/header"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Map from "../components/Map"
import SEO from "../components/seo"
import Section from "../components/Section"

const ContactPage = ({ data, pageContext: { locale } }) => {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout locale={locale}>
      <SEO title="Hostel Ljubas - contact" pathbame="/contact/" />
      <DefaultHeader contact={true}>
        <Banner title={frontmatter.title} />
      </DefaultHeader>
      <Section>
        <Article>{html}</Article>
      </Section>
      <Map />
    </Layout>
  )
}

export const contactQuery = graphql`
  query($path: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

export default ContactPage
