import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ImgWithGradient from "./ImgWithGradient"
import styled from "styled-components"

const GET_HEADER = graphql`
  {
    headerMurterBackground: file(
      relativePath: { eq: "header-image-murter.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerContactBackground: file(
      relativePath: { eq: "header-image-contact.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function DefaultHeader({ children, contact }) {
  return (
    <HeaderWrapper>
      <StaticQuery
        query={GET_HEADER}
        render={data => {
          return (
            <ImgWithGradient
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
              fluid={
                contact
                  ? data.headerContactBackground.childImageSharp.fluid
                  : data.headerMurterBackground.childImageSharp.fluid
              }
            />
          )
        }}
      />
      <div className="banner">{children}</div>
    </HeaderWrapper>
  )
}

export const HeaderWrapper = styled.header`
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  .banner {
    position: relative;
    z-index: 2;
  }
`
